.notification-container {
  position: fixed;
  font-size: 14px;
  box-sizing: border-box;
  z-index: 999999;

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0;
    color: $white;
    outline: none;
    text-shadow: 0 1px 0 $white;
    opacity: .8;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
  }
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}

.notification {
  position: relative;
  display: flex;
  width: 360px;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  background: $white;
  background-position: 15px;
  background-repeat: no-repeat;
  transition: .3s ease;
  pointer-events: auto;
  border-radius: 3px;
  box-shadow: 0 0 10px #999;
  color: $white;
  opacity: .9;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 12px $white;
    opacity: 1;
    cursor: pointer
  }

  .notification-image {
    margin-right: 12px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .notification-contents {
    flex: 1;
    min-width: 0;
  }
}

.notification-title {
  margin-bottom: 4px;
  font-weight: 600;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);

  }

  to {
    transform: translateX(0);
  }
}