// Here you can add other styles
@mixin setMaxHeight($maxHeight) {
    max-height: $maxHeight;
    height: auto !important;
    /* Ensures the height adjusts based on content */
    overflow-y: auto;
    /* Adds vertical scrollbar if content exceeds max height */
}

@mixin setHeight($height) {
    height: $maxHeight !important;
}

.import-data-table,
.product-images-component {
    @include setMaxHeight(500px);
}

::-webkit-file-upload-button,
::file-selector-button {
    display: none;
}

.row {
    --cui-gutter-x: 2rem !important;
}

.products-table,
.users-table,
.orders-table,
.overflow-table-body {
    .table-responsive {
        height: auto;
        max-height: 600px;
        overflow: auto;

        table {
            thead {
                top: -1px;
            }

            .products-table-body,
            .users-table-body {
                overflow-y: scroll !important;
            }
        }
    }
}

.remove-icon {

    polygon,
    path {
        fill: #d51414;
    }
}

.bg-red,
.btn-red {
    background-color: #d51414 !important;
    border-color: #d51414 !important;
    color: #fff !important;
}

.bg-orange,
.btn-orange {
    background-color: #f76f42 !important;
    border-color: #f76f42 !important;
    color: #fff !important;
}

.text-red {
    color: #d51414 !important;
}

.border-red {
    border-color: #d51414 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.questions-table {
    .table-responsive {
        height: auto;
        max-height: 600px;
        overflow: auto;

        table {
            thead {
                top: -1px;
            }

            .questions-table-body {
                overflow-y: scroll !important;
            }
        }
    }
}

.options-body {
    height: auto;
    max-height: 270px;
    overflow: auto;
}

.product-description-editor-wrapper {
    flex: 1;
    // height: 200px;

    &.height-400 {
        height: 400px;
    }

    .rdw-dropdownoption-default {
        color: black;
    }
}

// }

.product-description-editor-toolbar {
    height: auto;
    max-height: 100px;
    overflow: auto;
}

.description-editor-wrapper {
    .description-editor {
        min-height: 150px;
        height: auto;
        max-height: 200px;
    }
}

.product-images-body {
    height: auto;
    max-height: 400px;
    overflow: auto;

    img.product-image {
        object-fit: fill;
        width: 150px !important;
        height: 150px !important;
    }
}

.carousel-control-prev,
.carousel-control-next {
    &>span {
        width: 5rem;
        height: 5rem;
    }
}

.carousel-item>img {
    height: 300px;
    width: auto !important;
    max-width: 100%;
    // height: auto;
    margin: auto;
    // object-fit: fill;
}

.carousel-indicators {
    bottom: -20px !important;

    &>button {
        background-color: #fff !important;
    }
}

.questions-response-tab {
    height: auto;
    max-height: 500px;
    overflow: auto;
}

.product-noimage-container img {
    height: 300px !important;
    object-fit: contain;
}

.product-details {
    .product-description {
        height: auto;
        max-height: 200px;
        overflow: auto;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-product-content {
    height: auto;
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
}

div[class*=control][aria-disabled=true] {
    background-color: #dddcdc;
}

.carousel-height-100 {
    height: 100%;

    .carousel-inner {
        height: 100%;

        .carousel-item {
            .image-loading {
                @include setMaxHeight(40px);
            }
        }
    }
}

.add-tuning-product .carousel-item img {
    height: 150px;
}

li.nav-group .nav-link.nav-group-toggle {
    padding-left: 8px;
}

li.nav-group .nav-group-items {
    padding-left: 20px !important;
}

.tuning-data-bullets {
    line-height: 10px !important;
}
.blogmanagement-profile-img{
width: 150px;
height: 140px;
image-rendering: pixelated;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 10px;

}

.page p {
    margin-top: 0;
    margin-bottom: 0;
}
